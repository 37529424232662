import { Col, Container, Row, Table, Card, Spinner } from "react-bootstrap";
import BreadcrumbLayout from "../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { useState } from "react";

function Contact() {
  const { t } = useTranslation();
  const ContactData = t("Contact", { returnObjects: true });
  const [loadingMap, setLoadingMap] = useState(true);

  return (
    <>
      <BreadcrumbLayout title="Contact" />
      <Container fluid>
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading mb-4">
                <h3>{ContactData.title}</h3>
              </Col>
              <Col md={12}>
                <Card className="shadow-sm h-100">
                  <Card.Body>
                    <Row className="">

                      <Col md={6} className="">
                        <Card className="shadow-sm h-100">
                          <Card.Body>
                            <Row>
                              <div className="col-md-12 d-flex align-items-stretch">
                                <div className="info-wrap  w-100 p-lg-4 p-1 ">
                                  <h4 className="mb-4">{ContactData.heading}</h4>
                                  <div className="dbox w-100 d-flex align-items-start">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                      <span > <FaMapMarkerAlt /></span>
                                    </div>
                                    <div className="text pl-3">
                                      <p className="fw-bold"><span>{ContactData.address.title}</span> {ContactData.address.data}</p>
                                    </div>
                                  </div>
                                  <div className="dbox w-100 d-flex align-items-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                      <span><FaPhoneAlt /></span>
                                    </div>
                                    <div className="text pl-3">
                                      <p><span>{ContactData.contactNo.title}</span> <a href="tel://1234567920">{ContactData.contactNo.data}</a></p>
                                    </div>
                                  </div>
                                  <div className="dbox w-100 d-flex align-items-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                      <span> <FaEnvelope /></span>
                                    </div>
                                    <div className="text pl-3">
                                      <p><span>{ContactData.email.title}</span> <a href="mailto:dgccost@gmial.com">
                                        dgccost@gmial.com</a></p>
                                    </div>
                                  </div>
                                  <div className="dbox w-100 d-flex align-items-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                      <span><BsGlobeCentralSouthAsia /></span>
                                    </div>
                                    <div className="text pl-3">
                                      <p><span>{ContactData.website}</span> <a href="https://ccost.cg.gov.in">https://ccost.cg.gov.in</a></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={6} className="">
                        <Card className="shadow-sm h-100">
                          <Card.Body>
                            {loadingMap && (
                              <div className="text-center mb-3">
                                <Spinner animation="border" role="status" />
                              </div>
                            )}
                            <iframe
                              title="Google Maps"
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3717.8820047012496!2d81.6809564!3d21.2761378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13fe028c89624f9d%3A0x6352cbc6b659e914!2sChhattisgarh%20Council%20Of%20Science%20And%20Technology%20CGCOST!5e0!3m2!1sen!2sin!4v1705448760972!5m2!1sen!2sin"
                              width="100%"
                              height="400"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              onLoad={() => setLoadingMap(false)}
                            ></iframe>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={12} className="mt-3">
                        <Card className="shadow-sm h-100">
                          <Card.Body>
                            <h5 style={{ color: "rgb(8, 67, 170)", fontWeight: "bold" }}>{ContactData.offtitle}</h5>
                            <hr />
                            <Row>
                              <Col md={12}>

                                <div className="responsive-table">
                                  <Table bordered striped hover className="officers-list mt-3">
                                    <thead>
                                      <tr>
                                        <th>{t('Contact.sn')}</th>
                                        <th>{t('Contact.Inquiries')}</th>
                                        <th>{t('Contact.schemehead')}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ContactData.tblData.map((item) => (
                                        <tr key={item.sn} className="table-row-hover">
                                          <td style={{ textAlign: "center" }}>{item.sn}.</td>
                                          <td style={{ textAlign: "justify", color: "#0843aa", fontWeight: "bold" }}>
                                            {item.queries}
                                          </td>
                                          <td style={{ textAlign: "justify" }}>
                                            <div className="fw-bold">{item.name}</div>
                                            <div>
                                              <span className="fw-bold me-1">{ContactData.email.title} </span>
                                              <a href={`mailto:${item.email}`}>{item.email}</a>
                                            </div>
                                            <div>
                                              <span className="fw-bold">{ContactData.contactNo.title} </span>
                                              {item.phoneNo}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
