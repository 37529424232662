import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Row, Col, NavDropdown } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import logoEn from "../assets/images/logo-en.png";
import logoHi from "../assets/images/logo-hi.png";

import Swachh from "../assets/images/Swachh.png";
// import aajadi from "../assets/images/aajadi.png";
// import G20 from "../assets/images/G20.png";
import cglogoHi from "../assets/images/cgLogoHi.png";
import cglogoEn from "../assets/images/cgLogoEn.png";
import Swal from "sweetalert2";

function Header() {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const [dropdownOpen4, setDropdownOpen4] = useState(false);
  const [dropdownOpen5, setDropdownOpen5] = useState(false);

  const [dropdownOpen6, setDropdownOpen6] = useState(false);
  const [dropdownOpen7, setDropdownOpen7] = useState(false);
  const [dropdownOpen8, setDropdownOpen8] = useState(false);
  const [dropdownOpen9, setDropdownOpen9] = useState(false);
  const [dropdownOpen10, setDropdownOpen10] = useState(false);

  const [dropdownOpen11, setDropdownOpen11] = useState(false);
  const [dropdownOpen12, setDropdownOpen12] = useState(false);
  const [dropdownOpen13, setDropdownOpen13] = useState(false);
  const [dropdownOpen14, setDropdownOpen14] = useState(false);
  const [dropdownOpen15, setDropdownOpen15] = useState(false);
  const [dropdownOpen16, setDropdownOpen16] = useState(false);



  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const handleNavCollapse = () => {

    if (window.innerWidth <= 992) {
      setExpanded(!expanded);
    }
    else {
      setDropdownOpen1(false);
      setDropdownOpen2(false);
      setDropdownOpen3(false);
      setDropdownOpen4(false);
      setDropdownOpen5(false);
      setDropdownOpen6(false);
      setDropdownOpen7(false);
      setDropdownOpen8(false);
      setDropdownOpen9(false);
      setDropdownOpen10(false);
      setDropdownOpen11(false);
      setDropdownOpen12(false);
      setDropdownOpen13(false);
      setDropdownOpen14(false);
      setDropdownOpen15(false);
      setDropdownOpen16(false);
    }
  };

  const handleClick = (url) => {
    Swal.fire({
      html: lang === 2 ? `
            This link shall take you to a page/ website outside this website. `
        : ` यह लिंक आपको इस वेबसाइट के बाहर एक पेज/वेबसाइट पर ले जाएगा।`,
      icon: "warning",
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "OK",
      denyButtonText: "Cancel"
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(url);
      }
    });
  };


  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Navbar.Brand>
              <Row>
                <Col lg={3} md={3} sm={5} xs={7} className="p-1">
                  {i18n.language === "en" && (
                     <a href="/"
                     target="_self" rel="noopener noreferrer">
                    <img
                      src={logoEn}
                      className="d-inline img-fluid"
                      alt="Site Logo"
                      id="main-logo"
                    />
                      </a>
                  )}
                  {i18n.language === "hi" && (
                    <a href="/"
                    target="_self" rel="noopener noreferrer">
                    <img
                      src={logoHi}
                      className="d-inline-block img-fluid"
                      alt="Site Logo"
                      id="main-logo"
                    />
                    </a>
                  )}
                </Col>
                <Col lg={9} md={9} sm={7} xs={5} className="image-container">
                  {/* <a href="/"
                    onClick={(e) => {
                      e.preventDefault(); // Prevents the default link behavior
                      handleClick("https://www.g20.org/pt-br");
                    }}
                    target="_blank" rel="noopener noreferrer">
                    <img
                      src={G20}
                      className="d-none d-sm-block img-fluid"
                      alt="g20 Logo"
                    />
                  </a> */}
                  <a href="/"
                    onClick={(e) => {
                      e.preventDefault(); // Prevents the default link behavior
                      handleClick("https://www.mygov.in/");
                    }}
                    target="_blank" rel="noopener noreferrer">
                    <img
                      src={Swachh}
                      className="d-none d-md-block img-fluid"
                      alt="Swachh Logo"
                    />
                  </a>
                  {/* <a href="/"
                    onClick={(e) => {
                      e.preventDefault(); // Prevents the default link behavior
                      handleClick("https://amritmahotsav.nic.in/");
                    }}
                    target="_blank" rel="noopener noreferrer">
                    <img
                      src={aajadi}
                      className="d-none d-lg-block img-fluid"
                      alt="Aajadi Logo"
                    />
                  </a> */}
                  {i18n.language === "hi" && (
                    <a href="/"
                      onClick={(e) => {
                        e.preventDefault(); // Prevents the default link behavior
                        handleClick("https://cgstate.gov.in/home");
                      }}
                      target="_blank" rel="noopener noreferrer">
                      <img
                        src={cglogoHi}
                        className="img-fluid"
                        alt="Cg State Logo"
                      />
                    </a>
                  )}
                  {i18n.language === "en" && (
                    <a href="/"
                      onClick={(e) => {
                        e.preventDefault(); // Prevents the default link behavior
                        handleClick("https://cgstate.gov.in/en/home");
                      }}
                      target="_blank" rel="noopener noreferrer">
                      <img
                        src={cglogoEn}
                        className="img-fluid"
                        alt="Cg State Logo"
                      />
                    </a>
                  )}
                </Col>
              </Row>
            </Navbar.Brand>
          </Col>
        </Row>
        <Row className="header-menu">
          <Col>
            <Navbar
              collapseOnSelect
              expand="lg"
              expanded={expanded}
              onToggle={handleNavCollapse}
            >
              <Container fluid>
                <div className="d-lg-none">
                  <h1 style={{ color: "white" }}>Menu</h1>
                </div>
                <div className="d-lg-none">
                  <Navbar.Toggle
                    title="Menu"
                    aria-controls="responsive-navbar-nav"
                  />
                </div>
                <Navbar.Collapse id="responsive-navbar-nav border border-primary">
                  <Nav className="mx-auto">
                    <NavDropdown.Item
                      as={NavLink}
                      to=""
                      className="nav-link"
                      onClick={handleNavCollapse}
                    >
                      {t("menu.home")}
                    </NavDropdown.Item>

                    <NavDropdown
                      title={t("menu.about.title")}
                      id="collasible-nav-dropdown"
                      onMouseEnter={() => setDropdownOpen1(true)}
                      onMouseLeave={() => setDropdownOpen1(false)}
                      show={dropdownOpen1}
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/introduction"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.introduction")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/mission-objectives"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.missionObjectives")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/from-director-general"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.fromDirectorGeneral")}
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                        as={NavLink}
                        to="about/list-of-director-generals"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.listofDirectorGenerals")}
                      </NavDropdown.Item> */}
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/general-body"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.generalBody")}
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        to="about/executive-committee"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.executiveCommittee")}
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        to="about/annual-report"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.annualReport")}
                      </NavDropdown.Item>

                      {/* <NavDropdown
                        title={t("menu.about.memorandumofUnderstanding.title")}
                        drop={"end"}
                        className="dropSubMenu"
                        onClick={handleNavCollapse}
                      >
                         <NavDropdown.Item href="#">{t('menu.about.memorandumofUnderstanding.link')}</NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="about/memorandum-of-understanding/mou-with-nitrr"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t("menu.about.memorandumofUnderstanding.link")}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="about/memorandum-of-understanding/mou-with-other-institutes"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t("menu.about.memorandumofUnderstanding.link1")}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="about/memorandum-of-understanding/mou-with-ssn-ayurved-college"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t("menu.about.memorandumofUnderstanding.link2")}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="about/memorandum-of-understanding/mou-with-kalinga-university"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t("menu.about.memorandumofUnderstanding.link3")}
                        </NavDropdown.Item>
                      </NavDropdown> */}
                      <NavDropdown
                        title={t("menu.about.WhosWho.title")}
                        drop={"end"}
                        className="dropSubMenu"
                        // onClick={handleNavCollapse}
                        onMouseEnter={() => setDropdownOpen2(true)}
                        onMouseLeave={() => setDropdownOpen2(false)}
                        show={dropdownOpen2}
                      >
                        {/* <NavDropdown.Item href="#">{t('menu.about.WhosWho.link')}</NavDropdown.Item> */}
                        <NavDropdown.Item
                          as={NavLink}
                          to="about/whos-who/list-of-officers"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t("menu.about.WhosWho.link")}
                        </NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown.Item
                        as={NavLink}
                        to="about/organizational-chart"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.organizationalChart")}
                      </NavDropdown.Item>

                      {/* <NavDropdown.Item
                        as={NavLink}
                        to="about/work-allotment-of-scientists"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.workAllotmentofScientists")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/purchase-committee"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.purchaseCommittee")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/acts-rules"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.actsRules")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/instructions-for-medical-bills"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.instructionsforMedicalBills")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/terms-conditions-PhD-Course"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.termsConditionsPhDCourse")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/process-to-sanction-earned-leave"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.processToSanctionEarnedLeave")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="about/performance-appraisal-report"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.about.performanceAppraisalReport")}
                      </NavDropdown.Item> */}

                    </NavDropdown>

                    <NavDropdown.Item
                      as={NavLink}
                      to="services"
                      className="nav-link"
                      onClick={handleNavCollapse}
                    >
                      {t("menu.services")}
                    </NavDropdown.Item>

                    <NavDropdown

                      title={
                        <NavLink to="activities-of-council" className="dropdown-title" onClick={handleNavCollapse}>
                          {t("menu.activities-of-council.title")}
                        </NavLink>
                      }
                      id="collasible-nav-dropdown"

                      onMouseEnter={() => setDropdownOpen3(true)}
                      onMouseLeave={() => setDropdownOpen3(false)}
                      show={dropdownOpen3}
                    >
                      <NavDropdown

                        title={
                          <NavLink to="activities-of-council/cg-research-centre" className="dropdown-title" onClick={handleNavCollapse}>
                            {t(
                              "menu.activities-of-council.cg-research-centre.title"
                            )}

                          </NavLink>
                        }
                        drop={"end"}
                        className="dropSubMenu"
                        // onClick={handleNavCollapse}
                        onMouseEnter={() => setDropdownOpen4(true)}
                        onMouseLeave={() => setDropdownOpen4(false)}
                        show={dropdownOpen4}
                      >

                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-research-centre/completed-project"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-research-centre.completed-project"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-research-centre/ongoing-projects"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-research-centre.ongoing-projects"
                          )}
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown
                        title={
                          <NavLink to="activities-of-council/research-development-promotional-cell" className="dropdown-title" onClick={handleNavCollapse}>
                            {t(
                              "menu.activities-of-council.research-development-promotional-cell.title"
                            )}
                          </NavLink>
                        }

                        drop={"end"}
                        className="dropSubMenu"
                        // onClick={handleNavCollapse}
                        onMouseEnter={() => setDropdownOpen5(true)}
                        onMouseLeave={() => setDropdownOpen5(false)}
                        show={dropdownOpen5}
                      >
                        {/* <NavDropdown.Item href="#">{t('menu.activities-of-council.research-development-promotional-cell.mini-research-project')}</NavDropdown.Item> */}
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/research-development-promotional-cell/mini-research-project"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.research-development-promotional-cell.mini-research-project"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/research-development-promotional-cell/international-travel-grant"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.research-development-promotional-cell.international-travel-grant"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/research-development-promotional-cell/seminar-symposium-workshop-conference"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.research-development-promotional-cell.seminar-symposium-workshop-conference"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/research-development-promotional-cell/publication-grant"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.research-development-promotional-cell.publication-grant"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/research-development-promotional-cell/identification-new-areas"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.research-development-promotional-cell.identification-new-areas"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/research-development-promotional-cell/r-d-centres"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.research-development-promotional-cell.r-d-centres"
                          )}
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown

                        title={
                          <NavLink to="activities-of-council/cg-space-applications-centre" className="dropdown-title" onClick={handleNavCollapse}>
                            {t(
                              "menu.activities-of-council.cg-space-applications-centre.title"
                            )}
                          </NavLink>
                        }
                        drop={"end"}
                        className="dropSubMenu"
                        // onClick={handleNavCollapse}
                        onMouseEnter={() => setDropdownOpen6(true)}
                        onMouseLeave={() => setDropdownOpen6(false)}
                        show={dropdownOpen6}
                      >

                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-space-applications-centre/completed-projects"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-space-applications-centre.completed-projects"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-space-applications-centre/ongoing-projects"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-space-applications-centre.ongoing-projects"
                          )}
                        </NavDropdown.Item>

                        {/* <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-space-applications-centre/atlas-village-info-charra-kurud"
                          className="dropdown-item"
                           onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-space-applications-centre.atlas-village-info-charra-kurud"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-space-applications-centre/atlas-village-info-giraud-dharsiwa"
                          className="dropdown-item"
                           onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-space-applications-centre.atlas-village-info-giraud-dharsiwa"
                          )}
                        </NavDropdown.Item> */}

                        {/* <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-space-applications-centre/resource-atlas-of-chhattisgarh"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-space-applications-centre.resource-atlas-of-chhattisgarh"
                          )}
                        </NavDropdown.Item> */}
                        <NavDropdown
                          title={t(
                            "menu.activities-of-council.cg-space-applications-centre.atlas"
                          )}
                          drop={"end"}
                          className="dropSubMenu"
                          // onClick={handleNavCollapse}

                          onMouseEnter={() => setDropdownOpen16(true)}
                          onMouseLeave={() => setDropdownOpen16(false)}
                          show={dropdownOpen16}
                        >
                          {/* <NavDropdown.Item href="#">{t('menu.activities-of-council.cg-space-applications-centre.completed-project')}</NavDropdown.Item> */}
                          <NavDropdown.Item
                            as={NavLink}
                            to="activities-of-council/cg-space-applications-centre/resource-atlas-of-chhattisgarh"
                            className="dropdown-item"
                            onClick={handleNavCollapse}
                          >
                            {t(
                              "menu.activities-of-council.cg-space-applications-centre.resource-atlas-of-chhattisgarh"
                            )}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            as={NavLink}
                            to="activities-of-council/cg-space-applications-centre/raigarh-district-resource-atlas"
                            className="dropdown-item"
                            onClick={handleNavCollapse}
                          >
                            {t(
                              "menu.activities-of-council.cg-space-applications-centre.raigarh-district-resource-atlas"
                            )}
                          </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-space-applications-centre/map-gallery"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-space-applications-centre.map-gallery"
                          )}
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-space-applications-centre/dgps-guidelines"
                          className="dropdown-item"
                           onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-space-applications-centre.dgps-guidelines"
                          )}
                        </NavDropdown.Item> */}

                        {/* <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/cg-space-applications-centre/facility-in-the-cgsac"
                          className="dropdown-item"
                           onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.cg-space-applications-centre.facility-in-the-cgsac"
                          )}
                        </NavDropdown.Item> */}

                      </NavDropdown>

                      <NavDropdown
                        title={
                          <NavLink to="activities-of-council/popularisation-of-science" className="dropdown-title" onClick={handleNavCollapse}>
                            {t(
                              "menu.activities-of-council.popularisation-of-science.title"
                            )}
                          </NavLink>
                        }
                        drop={"end"}
                        className="dropSubMenu"
                        onMouseEnter={() => setDropdownOpen7(true)}
                        onMouseLeave={() => setDropdownOpen7(false)}
                        show={dropdownOpen7}
                      >
                        {/* <NavDropdown.Item href="activities-of-council/popularisation-of-science"></NavDropdown.Item> */}
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/cg-young-scientist-congress"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.cg_young_scientist_congress"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/national-children-science-congress"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.national_children_science_congress"
                          )}
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/western-india-science-fair"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.western_india_science_fair"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/national-science-seminar"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.national_science_seminar"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/science-quiz-competitions"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.science_quiz_competitions"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/community-science-club"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.community_science_club"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/popular-science-book-corner"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.popular_science_book_corner"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/science-park"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.science_park"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/mathematical-olympiad"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.mathematical_olympiad"
                          )}
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/science-technology-communication-centre"
                          className="dropdown-item"
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.science_technology_communication_centre"
                          )}
                        </NavDropdown.Item> */}
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/national-science-day"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.national_science_day"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/national-mathematics-day"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.national_mathematics_day"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/mobile-science-lab"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.mobile_science_lab"
                          )}
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/mobile-science-cxhibition-van"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.mobile_science_cxhibition_van"
                          )}
                        </NavDropdown.Item> */}
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/vigyan-prasar-edusat-program"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.vigyan_prasar_edusat_program"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/science-society-programme"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.science_society_programme"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/mobile-planetarium"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.mobile_planetarium"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/popularisation-of-science/world-space-week"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.popularisation-of-science.world_space_week"
                          )}
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown
                        title={
                          <NavLink to="activities-of-council/science-technology-intervention-cell" className="dropdown-title" onClick={handleNavCollapse}>
                            {t(
                              "menu.activities-of-council.science_technology_intervention_cell.title"
                            )}
                          </NavLink>
                        }
                        drop={"end"}
                        className="dropSubMenu"
                        // onClick={handleNavCollapse}
                        onMouseEnter={() => setDropdownOpen8(true)}
                        onMouseLeave={() => setDropdownOpen8(false)}
                        show={dropdownOpen8}
                      >
                        {/* <NavDropdown.Item href="activities-of-council/science_technology_intervention_cell"></NavDropdown.Item> */}
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/science-technology-intervention-cell/technology-village-programme"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.science_technology_intervention_cell.technology_village_programme"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/science-technology-intervention-cell/technology-transfer"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.science_technology_intervention_cell.technology_transfer"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/science-technology-intervention-cell/information-technology-centre-for-s-t"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.science_technology_intervention_cell.information_technology_centre_for_s_t"
                          )}
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown.Item
                        as={NavLink}
                        to="activities-of-council/research-and-publication"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t(
                          "menu.activities-of-council.research_and_Publication"
                        )}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="activities-of-council/activities-coordinator-cell"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t(
                          "menu.activities-of-council.activities_coordinator_cell"
                        )}
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        to="activities-of-council/documentation-communication-centre"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t(
                          "menu.activities-of-council.documentation_communication_centre"
                        )}
                      </NavDropdown.Item>

                      <NavDropdown

                        title={
                          <NavLink to="activities-of-council/intellectual-property-rights-centre" className="dropdown-title" onClick={handleNavCollapse}>
                            {t(
                              "menu.activities-of-council.intellectual_property_rights_centre.title"
                            )}
                          </NavLink>
                        }
                        drop={"end"}
                        className="dropSubMenu"
                        // onClick={handleNavCollapse}
                        onMouseEnter={() => setDropdownOpen9(true)}
                        onMouseLeave={() => setDropdownOpen9(false)}
                        show={dropdownOpen9}
                      >

                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/intellectual-property-rights-centre/patent-information-center"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                           {t(
                              "menu.activities-of-council.intellectual_property_rights_centre.patent_information_center"
                            )}
                          
                        </NavDropdown.Item>


                        <NavDropdown
                          title={
                            <NavLink to="activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre" className="dropdown-title" onClick={handleNavCollapse}>
                              {t(
                              "menu.activities-of-council.intellectual_property_rights_centre.intellectual_property_facilitation.title"
                            )}
                              
                            </NavLink>
                          }

                          drop={"end"}
                          className="dropSubMenu"
                          // onClick={handleNavCollapse}
                          onMouseEnter={() => setDropdownOpen10(true)}
                          onMouseLeave={() => setDropdownOpen10(false)}
                          show={dropdownOpen10}
                        >


                          <NavDropdown.Item
                            as={NavLink}
                            to="activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre/aspirational-block-project"
                            className="dropdown-item"
                            onClick={handleNavCollapse}
                          >
                            {t(
                              "menu.activities-of-council.intellectual_property_rights_centre.intellectual_property_facilitation.aspirational_block_project"
                            )}
                            


                          </NavDropdown.Item>
                          <NavDropdown.Item
                            as={NavLink}
                            to="activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre/IP-yatra-program "
                            className="dropdown-item"
                            onClick={handleNavCollapse}
                          >
                            {t(
                              "menu.activities-of-council.intellectual_property_rights_centre.intellectual_property_facilitation.ip_yatra_program"
                            )}
                           


                          </NavDropdown.Item>

                        </NavDropdown>

                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/intellectual-property-rights-centre/grassroot-innovation-augmentation-network"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                              "menu.activities-of-council.intellectual_property_rights_centre.grassroot_innovation_augmentation_network_cell"
                            )}
                          
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/intellectual-property-rights-centre/innovation-fund"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                              "menu.activities-of-council.intellectual_property_rights_centre.innovation_fund"
                            )}
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/intellectual-property-rights-centre/world-intellectual-property-organization"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                              "menu.activities-of-council.intellectual_property_rights_centre.world_intellectual_property_organization"
                            )}
                        </NavDropdown.Item>

                        {/* <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/intellectual-property-rights-centre/innovation-fund"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.intellectual_property_rights_centre.innovation_fund"
                          )}
                        </NavDropdown.Item> */}
                      </NavDropdown>

                      <NavDropdown.Item
                        as={NavLink}
                        to="activities-of-council/forest-eco-plan-centre"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.activities-of-council.forest_eco_plan_centre")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="activities-of-council/ccost-activity-profile"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.activities-of-council.ccost_activity_profile")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="activities-of-council/establishment-of-snrms"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.activities-of-council.establishment_of_snrms")}
                      </NavDropdown.Item>
                      <NavDropdown
                        title={
                          <NavLink to="activities-of-council/services-provided-by-the-council" className="dropdown-title" onClick={handleNavCollapse}>
                            {t(
                              "menu.activities-of-council.services_provided_by_the_council.title"
                            )}
                          </NavLink>
                        }

                        drop={"end"}
                        className="dropSubMenu"
                        // onClick={handleNavCollapse}
                        onMouseEnter={() => setDropdownOpen11(true)}
                        onMouseLeave={() => setDropdownOpen11(false)}
                        show={dropdownOpen11}
                      >

                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/services-provided-by-the-council/central-laboratory-facility"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.services_provided_by_the_council.central_laboratory_facility"
                          )}
                        </NavDropdown.Item>
                      </NavDropdown>

                      {/* <NavDropdown.Item
                        as={NavLink}
                        to="activities-of-council/ccost-as-knowledge-partner"
                        className="dropdown-item"
                         onClick={handleNavCollapse}
                      >
                        {t(
                          "menu.activities-of-council.ccost_as_knowledge_partner"
                        )}
                      </NavDropdown.Item> */}
                      {/* <NavDropdown.Item
                        as={NavLink}
                        to="activities-of-council/18th-cg-young-scientist-congress"
                        className="dropdown-item"
                         onClick={handleNavCollapse}
                      >
                        {t(
                          "menu.activities-of-council.18th_cg_young_scientist_congress"
                        )}
                      </NavDropdown.Item> */}
                      <NavDropdown

                        title={
                          <NavLink to="activities-of-council/other-projects" className="dropdown-title" onClick={handleNavCollapse}>
                            {t(
                              "menu.activities-of-council.other_projects.title"
                            )}
                          </NavLink>
                        }
                        drop={"end"}
                        className="dropSubMenu"
                        // onClick={handleNavCollapse}
                        onMouseEnter={() => setDropdownOpen12(true)}
                        onMouseLeave={() => setDropdownOpen12(false)}
                        show={dropdownOpen12}
                      >
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/other-projects/completedp-projects"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.other_projects.completed_projects"
                          )}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={NavLink}
                          to="activities-of-council/other-projects/ongoing-projects"
                          className="dropdown-item"
                          onClick={handleNavCollapse}
                        >
                          {t(
                            "menu.activities-of-council.other_projects.ongoing_projects"
                          )}
                        </NavDropdown.Item>
                      </NavDropdown>
                    </NavDropdown>

                    <NavDropdown

                      title={t("menu.e-citizens.title")}
                      id="collasible-nav-dropdown"
                      onMouseEnter={() => setDropdownOpen13(true)}
                      onMouseLeave={() => setDropdownOpen13(false)}
                      show={dropdownOpen13}
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="e-citizens/right-to-information"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.e-citizens.right-to-information")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="e-citizens/download-pdf"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.e-citizens.download-pdf")}
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                        as={NavLink}
                        to="e-citizens/advertisement"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.e-citizens.advertisement")}
                      </NavDropdown.Item> */}
                      <NavDropdown.Item
                        as={NavLink}
                        to="e-citizens/circulars"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.e-citizens.circulars")}
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        to="e-citizens/council-magazine"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.e-citizens.council-magazine")}
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown
                      title={t("menu.media-gallery.title")}
                      id="collasible-nav-dropdown"
                      onMouseEnter={() => setDropdownOpen14(true)}
                      onMouseLeave={() => setDropdownOpen14(false)}
                      show={dropdownOpen14}
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="media-gallery/photo-gallery"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.media-gallery.photo-gallery")}
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        to="media-gallery/poster-gallery"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.media-gallery.poster-gallery")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="media-gallery/video-gallery"
                        className="dropdown-item"
                         onClick={handleNavCollapse}
                      >
                        {t("menu.media-gallery.video-gallery")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="media-gallery/press-gallery"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.media-gallery.press-gallery")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="media-gallery/presentation-gallery"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.media-gallery.presentation-gallery")}
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                        as={NavLink}
                        to="media-gallery/know-your-district"
                        className="dropdown-item"
                         onClick={handleNavCollapse}
                      >
                        {t("menu.media-gallery.know-your-district")}
                      </NavDropdown.Item> */}
                    </NavDropdown>

                    <NavDropdown.Item
                      as={NavLink}
                      to="achievements"
                      className="nav-link"
                      onClick={handleNavCollapse}
                    >
                      {t("menu.achievements")}
                    </NavDropdown.Item>

                    <NavDropdown
                      title={t("menu.noticeboard.title")}
                      id="collasible-nav-dropdown"
                      onMouseEnter={() => setDropdownOpen15(true)}
                      onMouseLeave={() => setDropdownOpen15(false)}
                      show={dropdownOpen15}
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="notice-board/news-events"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.noticeboard.news-events")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="notice-board/tenders"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.noticeboard.tenders")}
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                        as={NavLink}
                        to="notice-board/circulars"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.noticeboard.circulars")}
                      </NavDropdown.Item> */}
                      <NavDropdown.Item
                        as={NavLink}
                        to="notice-board/advertisement"
                        className="dropdown-item"
                        onClick={handleNavCollapse}
                      >
                        {t("menu.noticeboard.advertisement")}
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown.Item
                      as={NavLink}
                      to="quick-links"
                      className="nav-link"
                      onClick={handleNavCollapse}
                    >
                      {t("menu.quickLinks")}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={NavLink}
                      to="contact-us"
                      className="nav-link"
                      onClick={handleNavCollapse}
                    >
                      {t("menu.contactUs")}
                    </NavDropdown.Item>

                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default Header;
